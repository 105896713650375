<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            Level
            <v-spacer></v-spacer>
            <add-button
              permission="grade-create"
              @action="(dialog = true, editId = null)"
              >New Levels
            </add-button>
          </v-card-title>

          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="data"
            :options.sync="pagination"
            :server-items-length="data.length"
            :loading="isLoading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td class="text-left text-bold">
                  <strong>{{ item.name }}</strong>
                </td>
               
                <td class="text-left text-bold">
                  <span v-for="(facultyItem, facultyIndex) in item.faculty" :key="facultyIndex">
                    <v-chip class="mb-1 mt-1 mr-2"
                    small
                    label
                    outlined
                    color="primary"
                  >
                  {{ facultyItem.name }}</v-chip
                  >
                    <!-- {{ facultyItem.name }} -->
                  </span>
                </td>
                <td class="text-center">
                  <v-chip
                    small
                    label
                    outlined
                    v-if="item.status === 1"
                    color="success"
                  >
                    Active</v-chip
                  >
                  <v-chip
                    small
                    label
                    outlined
                    v-if="item.status === 0"
                    color="error"
                  >
                    Inactive</v-chip
                  >
                </td>
                <td class="text-right" style="display: flex; align-items: center">
                  <view-button 
                    permission="section-read"
                    @click.native="program(item.id)"
                  />

                  <edit-button
                    v-if="item.allowed"
                    permission="grade-update"
                    @agree="editLevel(item)"
                  />
                  <delete-button
                    v-if="item.allowed"
                    :permission="true"
                    @agree="deleteLevel(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="dialog" persistent max-width="400px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <v-form
              ref="form"
              v-model="valid"
              :lazy-validation="lazy"
              @submit.prevent="save"
              @keyup.enter="save"
            >
            
              <v-select
                id="faculty_filter"
                label="Select Faculty"
                item-text="title"
                item-value="value"
                class="pa-0 faculty_filter"
                outlined
                dense
                multiple
                v-model="levelRecord.faculty_id"
                :items="facultyList"
                :loading="facultyLoading"
              />

              <v-text-field
                autocomplete="off"
                label="Name*"
                required
                class="pa-0"
                outlined
                dense
                v-model="levelRecord.name"
                name="name"
                :rules="[(v) => !!v || 'Name is required']"
              />

              <v-select
                :items="statuses"
                required
                class="pa-0"
                label="Status*"
                outlined
                dense
                v-model="levelRecord.status"
                :rules="[(v) => !!v || 'Status is required']"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text @click="dialog = false">Close</v-btn>
            <v-btn 
            :loading="saving" 
            color="success" 
            text 
            @click="validate"
              >{{ editId === null ? 'Save' : 'Update' }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
	mixins: [Mixins],
	data: () => ({
		saving: false,
		lazy: false,
		isLoading: false,
		dialog: false,

		levelRecord: {
			name: "",
			status: "1",
			faculty_id: [],
			type: "level",
		},
		headers: [
			{ text: "#", align: "left", value: "id", width: 50, sortable: false },
			{ text: "Name", align: "left", value: "name", sortable: false },
			{ text: "Selected Faculty", align: "left", value: "name", sortable: false, width: 700 },
			{ text: "Status", align: "center", value: "rank", width: 110 },
			{ text: "Action", align: "right", sortable: false, width: 200 },
		],
		statuses: [
			{
				text: "Active",
				value: "1",
			},
			{
				text: "Inactive",
				value: "0",
			},
		],
		facultyLoading: false,
		facultyList: [],
		selectedFaculty: null,
		data: [],
		editId: null,
	}),
	mounted() {
		this.fetchFaculty();
	},
	computed: {},

	watch: {
		pagination: function () {
			this.get();
		},
	},
	methods: {
		validate() {
			if (this.$refs.form.validate()) {
				this.save();
			}
		},
		fetchFaculty() {
			this.facultyLoading = true;
			this.$rest
				.get("/api/affiliation-faculty?type=faculty")
				.then(({ data }) => {
					this.facultyList = data.data.map((res) => {
						return { title: res.name, value: res.id };
					});
				})
				.finally(() => {
					this.facultyLoading = false;
				});
		},

		get() {
			const extraParams = "?type=level";
			this.isLoading = true;
			this.$rest
				.get('/api/level-program' + extraParams)
				.then((res) => {
					this.data = res.data.data;
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		save(id) {
			this.saving = true;

			if (this.editId === null) {
				this.$rest
					.post('/api/level-program', this.levelRecord)
					.then(() => {
						this.$events.fire("notification", {
							message: "Level was created successfully",
							status: "success",
						});
						this.resetAfterSaveUpdate();
					})
					.finally(() => {
						this.saving = false;
					});
			}
			else {
				this.$rest.put("/api/level-program/" + this.editId, this.levelRecord).then(() => {
					this.$events.fire('notification', { message: 'Level was edited successfully', status: 'success' });
					this.resetAfterSaveUpdate();
				}).finally(() => {
					this.saving = false;
				}) 

			}
      
		},

		resetAfterSaveUpdate() {
			this.get();
			this.saving = false;
			this.dialog = false;
			this.levelRecord.name = "";
			this.levelRecord.status = "1";
			this.levelRecord.faculty_id = [];
		},

		deleteLevel(id) {
			this.$rest.delete('/api/level-program/' + id).then((res) => {
				this.get();
				this.$events.fire("notification", {
					message: "Level was deleted.",
					status: "success",
				});
			});
		},

		editLevel(item) {
			this.levelRecord.name = item.name;
			this.levelRecord.status = "1";
			this.levelRecord.faculty_id = item.faculty.map((faculty) => faculty.id); // Extracting multiple id.
			this.dialog = true;
			this.editId = item.id;

		},
		program(id) {
			this.$router.push({
				name: "program",
				params: {
					levelId: id,
				},
			});
		},
	},
};
</script>
<style lang="scss" scoped></style>
